<template>
<div class="app-container">
  <el-col :sm="24" :md="16" >
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>Registrar empleado</span>
      </div>
      <div class="text item">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <el-form label-position="top" label-width="100px" :model="form" ref="formMessages">
            <el-image :src="$urlGlobalApi + form.avatar">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <el-form-item label="Foto de Perfil">
            <el-upload
                drag
                name="image"
                action="#"
                :http-request="postImage"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
                :limit="1"
                :on-exceed="handleExceed">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Suelta tu foto aquí o <em>haz clic para cargar</em></div>
                <div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>
              </el-upload>
            </el-form-item>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID Usuario*" size="mini">
                <ValidationProvider name="ID usuario" rules="required|min:1|max:5" v-slot="{ errors }">
                  <el-input v-model="form.idEmployed"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Nombre*" size="mini">
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                  <el-input v-model="form.name"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Correo electrónico" size="mini">
                <!-- <ValidationProvider name="email" rules="required" v-slot="{ errors }"> -->
                  <el-input v-model="form.email"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Contraseña" size="mini">
              <!--<ValidationProvider name="contraseña" rules="confirmed:passwordc|min:6" v-slot="{ errors }">
                <el-input v-model="form.password" show-password></el-input>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>-->
              <el-input v-model="form.password" show-password></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Confirmar contraseña" size="mini">
                <!--<ValidationProvider name="confirmacion contraseña" vid="passwordc" v-slot="{ errors }">
                  <el-input v-model="form.password_confirmation" show-password></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>-->
                <el-input v-model="form.password_confirmation" show-password></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Número celular" size="mini">
                <!--<ValidationProvider name="Número celular" rules="min:8|max:13" v-slot="{ errors }">
                  <el-input v-model="form.phoneCelular"></el-input>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>-->
                <el-input v-model="form.phoneCelular"></el-input>
                <span>Ingrese <strong>+521</strong> al inicio seguido de su numero ejemplo: +5215547113678</span>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="RFC" size="mini" class="text-uppercase">
                <!-- <ValidationProvider name="RFC" rules="required" v-slot="{ errors }"> -->
                  <el-input v-model="form.RFC"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="CURP" size="mini">
                <!-- <ValidationProvider name="CURP" rules="required|min:18" v-slot="{ errors }"> -->
                  <el-input v-model="form.CURP"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="NSS" size="mini">
                <!-- <ValidationProvider name="Numero Seguridad Social" rules="required" v-slot="{ errors }"> -->
                  <el-input v-model="form.NSS"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Días de vacaciones" size="mini">
                <!-- <ValidationProvider name="Dias de vacaciones" rules="integer" v-slot="{ errors }"> -->
                  <el-input-number v-model="form.vacationDays" controls-position="right" :min="0"></el-input-number>
                  <!-- <el-input v-model="form.vacationDays"></el-input> -->
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID puesto" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.idJob"
                  filterable
                  allow-create
                  clearable
                  placeholder=" ID puesto">
                    <el-option
                      v-for="itemUser in listJobid"
                      :key="itemUser.idJob"
                      :label="itemUser.idJob"
                      :value="itemUser.idJob">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.job }}</span>
                  <span style="float: left">{{ itemUser.idJob }}</span>
                    </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Puesto" size="mini">
                <!-- <el-input v-if="listJobid.idJob == form.idJob" v-model="form.job">hola</el-input> -->

                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.job"
                  filterable
                  allow-create
                  clearable
                  placeholder="Puesto">
                    <el-option
                      v-for="itemUser in listJobid"
                      :key="itemUser.idJob"
                      :label="itemUser.job"
                      :value="itemUser.job">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.idJob }}</span>
                  <span style="float: left">{{ itemUser.job }}</span>
                    </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID Lugar de trabajo" size="mini">
              <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.idWorkPlace"
                  filterable
                  allow-create
                  clearable
                  placeholder=" ID Lugar de Trabajo">
                    <el-option
                      v-for="itemUser in listWorkPlace"
                      :key="itemUser.idWorkPlace"
                      :label="itemUser.idWorkPlace"
                      :value="itemUser.idWorkPlace">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.workPlace }}</span>
                  <span style="float: left">{{ itemUser.idWorkPlace }}</span>
                    </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Lugar de trabajo" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.workPlace"
                  filterable
                  allow-create
                  clearable
                  placeholder="Lugar de Trabajo">
                    <el-option
                      v-for="itemUser in listWorkPlace"
                      :key="itemUser.idWorkPlace"
                      :label="itemUser.workPlace"
                      :value="itemUser.workPlace">
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.idWorkPlace }}</span>
                    <span style="float: left">{{ itemUser.workPlace }}</span>
                    </el-option>
          </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID Unidad de trabajo" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.idUnit"
                  filterable
                  allow-create
                  clearable
                  placeholder=" ID Unidad de Trabajo">
                    <el-option
                      v-for="itemUser in listUnitPlace"
                      :key="itemUser.idUnit"
                      :label="itemUser.idUnit"
                      :value="itemUser.idUnit">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.unit }}</span>
                  <span style="float: left">{{ itemUser.idUnit }}</span>
                    </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Unidad de trabajo" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.unit"
                  filterable
                  allow-create
                  clearable
                  placeholder="Unidad de Trabajo">
                    <el-option
                      v-for="itemUser in listUnitPlace"
                      :key="itemUser.idUnit"
                      :label="itemUser.unit"
                      :value="itemUser.unit">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.idUnit }}</span>
                  <span style="float: left">{{ itemUser.unit }}</span>
                    </el-option>
              </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID Dirección" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.idTeam"
                  filterable
                  allow-create
                  clearable
                  placeholder=" ID Dirección">
                    <el-option
                      v-for="itemUser in listTeamid"
                      :key="itemUser.idTeam"
                      :label="itemUser.idTeam"
                      :value="itemUser.idTeam">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.team }}</span>
                  <span style="float: left">{{ itemUser.idTeam }}</span>
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Dirección" size="mini">
                <el-select
                  class="width-100"
                  size="mini"
                  v-model="form.team"
                  filterable
                  allow-create
                  clearable
                  placeholder=" ID Dirección">
                    <el-option
                      v-for="itemUser in listTeamid"
                      :key="itemUser.idTeam"
                      :label="itemUser.team"
                      :value="itemUser.team">
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUser.idTeam }}</span>
                  <span style="float: left">{{ itemUser.team }}</span>
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="ID Región" size="mini">
                <!-- <ValidationProvider name="Region ID" v-slot="{ errors }"> -->
                  <el-input v-model="form.idRegion"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Región" size="mini">
                <!-- <ValidationProvider name="region" v-slot="{ errors }"> -->
                  <el-input v-model="form.region"></el-input>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Fecha de antigüedad*">
                <ValidationProvider name="fecha de antigüedad" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.dateAntiquity"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker><br>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Fecha de finalización">
                <!-- <ValidationProvider name="finish date" rules="required" v-slot="{ errors }"> -->
                  <el-date-picker
                    size="mini"
                    v-model="form.endDate"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker><br>
                  <!-- <span>{{ errors[0] }}</span> -->
                <!-- </ValidationProvider> -->
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Fecha de nacimiento*">
                <ValidationProvider name="fecha de nacimiento" rules="required" v-slot="{ errors }">
                  <el-date-picker
                    size="mini"
                    v-model="form.birthDate"
                    type="date"
                    format="dd-MM-yyyy"
                    placeholder="Fecha">
                  </el-date-picker><br>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </el-form-item>
            </el-col>
            <el-col>
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>Contacto de emergencia</span>
                </div>
                <div class="text item">
                  <el-col :sm="24" :md="10" :offset="1" >
                    <el-form-item label="Nombre" size="mini">
                      <!-- <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }"> -->
                        <el-input v-model="form.emergencyContactName"></el-input>
                        <!-- <span>{{ errors[0] }}</span> -->
                      <!-- </ValidationProvider> -->
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="10" :offset="1" >
                    <el-form-item label="Teléfono" size="mini">
                      <!-- <ValidationProvider name="Telefono" rules="required" v-slot="{ errors }"> -->
                        <el-input v-model="form.emergencyContactPhone"></el-input>
                        <span>Ingrese <strong>+521</strong> al inicio seguido de su numero ejemplo: +5215547113678</span>
                        <!-- <span>{{ errors[0] }}</span> -->
                      <!-- </ValidationProvider> -->
                    </el-form-item>
                  </el-col>
                </div>
              </el-card>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item label="Estatus*" class="size-tips">
                <ValidationProvider name="estatus" rules="required" v-slot="{ errors }">
                  <el-select
                  size="mini"
                  v-model="form.status"
                  filterable
                  placeholder="Estatus">
                    <el-option
                      v-for="item in optionsStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select><br>
                  <span>{{ errors[0] }}</span><br>
                </ValidationProvider>
                <span>Si el estatus es <strong>No</strong> el usuario no estará visible dentro de la App</span>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="10" :offset="1" >
              <el-form-item class="margin-top-20">
                <el-button
                  @click.stop.prevent="onStore"
                  size="mini"
                  type="primary"
                  :disabled="invalid"
                  :loading="loading"
                  icon="el-icon-upload2">
                  Registar</el-button>
                  <el-button
                  @click.stop.prevent="onCancel"
                  size="mini"
                  type="danger"
                  icon="el-icon-circle-close">
                  Cancelar</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </ValidationObserver>
      </div>
    </el-card>
  </el-col>
</div>
</template>
<script>
import { storeUser, getWorkplace, getUnitplace, getTeamid, getJobid } from '@/api/user'
import { uploadImageFile } from '@/api/image'

export default {
  data () {
    return {
      form: {
        title: '',
        description: '',
        publishedDate: '',
        idEmployed: '',
        name: '',
        email: '',
        avatar: '',
        RFC: '',
        CURP: '',
        NSS: '',
        birthDate: '',
        dateAntiquity: '',
        password: '',
        status: true,
        idJob: '',
        job: '',
        idUnit: '',
        unit: '',
        idTeam: '',
        team: '',
        idRegion: '',
        region: '',
        idWorkPlace: '',
        workPlace: '',
        vacationDays: [],
        emergencyContactName: '',
        emergencyContactPhone: ''
      },
      addValue: {},
      optionsStatus: [{
        value: true,
        label: 'Si'
      }, {
        value: false,
        label: 'No'
      }],
      loading: false,
      listWorkPlace: [],
      listUnitPlace: [],
      listJobid: [],
      listTeamid: []
    }
  },
  created () {
    this.listWorkPlaceTitle()
    this.listUnitPlaceTitle()
    this.listJobIdTitle()
    this.listTeamIdTitle()
    // this.sendSecondValue()
  },
  methods: {
    async onStore () {
      await storeUser(this.form)
        .then(() => {
          // let res = response.data
          this.$message({
            showClose: true,
            message: 'Se ha creado un nuevo usuario!',
            type: 'success'
          })
          this.$router.push({ name: 'users' })
        })
        .catch(this.responseCatch)
    },
    async postImage (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', param.file)
      formData.append('width', '300')
      formData.append('height', '300')
      formData.append('title', 'Avatar employer')
      formData.append('alt', 'ApiAvigrupo')
      formData.append('folder', 'img/avatar')
      uploadImageFile(formData).then((response) => {
        // console.log(response)
        param.onSuccess(response.data.path)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccess (res, file) {
      this.form.avatar = res
      // console.log(this.form.image)
    },
    async listWorkPlaceTitle () {
      this.loading = true
      await getWorkplace()
        .then(response => {
          console.log(response)
          this.listWorkPlace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listUnitPlaceTitle () {
      this.loading = true
      await getUnitplace()
        .then(response => {
          console.log(response)
          this.listUnitPlace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listJobIdTitle () {
      this.loading = true
      await getJobid()
        .then(response => {
          console.log(response)
          this.listJobid = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listTeamIdTitle () {
      this.loading = true
      await getTeamid()
        .then(response => {
          console.log(response)
          this.listTeamid = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleExceed (files, fileList) {
      this.$message.warning(`El límite es 1, haz seleccionado ${files.length} archivos esta vez`)
    },
    async onCancel () {
      this.$router.push({ name: 'users' })
    }
    // async addSecondValue () {
    //   for (let i )
    //   console.log(this.addValue)
    // }
  }
}
</script>
